import { driver } from "driver.js";

export const unitListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Unit list.",
        },
      },
      {
        element: "#unit_list_createbtn",
        popover: {
          title: "Create new unit",
          description: "When click this button you can create new unit.",
        },
      },
      {
        element: "#unit_list_table",
        popover: {
          title: "Unit List Table",
          description:
            "Table displaying unit List details where you can delete and edit unit.",
        },
      },
      {
        element: "#unit_list_edit",
        popover: {
          title: "Edit Unit",
          description: "Click this button to edit the unit.",
        },
      },
      {
        element: "#unit_list_delete",
        popover: {
          title: "Delete Unit",
          description: "Click this button to delete the unit.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage unit List.",
        },
      },
    ],
  });
};

export const editUnitGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this edit Unit.",
        },
      },
      {
        element: "#unit_edit_discipline",
        popover: {
          title: "Edit Discipline ",
          description: "Edit Discipline",
        },
      },
      {
        element: "#unit_edit_symbol",
        popover: {
          title: "Edit Symbol",
          description: "Edit Symbol",
        },
      },
      {
        element: "#unit_edit_save",
        popover: {
          title: "Save unit",
          description: "Click this button to save the unit.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can edit unit",
        },
      },
    ],
  });
};

export const createUnitGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this create Unit.",
        },
      },
      {
        element: "#unit_create_discipline",
        popover: {
          title: "Select Discipline ",
          description: "Select Discipline",
        },
      },
      {
        element: "#unit_create_symbol",
        popover: {
          title: "Enter Symbol",
          description: "Enter Symbol",
        },
      },
      {
        element: "#unit_create_save",
        popover: {
          title: "Save unit",
          description: "Click this button to save the unit.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create unit",
        },
      },
    ],
  });
};
