import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../psuedoApi";

export const [
  getStandardsThunk,
  getInstrumentsThunk,
  getDisciplinesThunk,
  getClientsThunk,
  getUnitsThunk,
  getUncertaintiesThunk,
  getSettingsThunk,
  getUsersThunk,
] = [
  ["standards", "getStandards"],
  ["instruments", "getInstruments"],
  ["disciplines", "getDisciplines"],
  ["clients", "getClients"],
  ["units", "getUnits"],
  ["uncertianties", "getUncertainties"],
  ["settings", "getSettings"],
  ["users", "getUsers"],
].map((paths) =>
  createAsyncThunk(`data/${paths[0]}`, async (params, thunkApi) => {
    console.log("here 0!");
    let data = thunkApi.getState().globalData[paths[0]];
    if (!data.length || (params && params.refresh))
      data = await request({
        url: paths[1],
        params,
        type: "get",
      });
    switch (paths[0]) {
      case "standards":
        thunkApi.dispatch(setStandrds({ clients: data }));
        break;
      case "instruments":
        thunkApi.dispatch(setInstruments({ instruments: data }));
        break;
      case "disciplines":
        thunkApi.dispatch(setDisciplines({ disciplineS: data }));
        break;
      case "clients":
        data = data.map((c) => {
          try {
            c.contact = JSON.parse(c.contact);
          } catch (e) {}
          return c;
        });
        thunkApi.dispatch(setClients({ clients: data }));
        break;
      case "units":
        thunkApi.dispatch(setUnits({ units: data }));
        break;
      case "uncertianties":
        thunkApi.dispatch(setUncertainties({ uncertainties: data }));
        break;
      case "settings":
        thunkApi.dispatch(setSettings({ settings: data }));
        break;
      case "users":
        thunkApi.dispatch(setUser({ users: data }));
        break;
      default:
        break;
    }
  })
);

const initialState = {
  standards: [],
  instruments: [],
  disciplines: [],
  clients: [],
  units: [],
  uncertainties: [],
  settings: [],
  users: [],
};

export const globalDataSlice = createSlice({
  name: "globalData",
  initialState,
  reducers: {
    setStandrds: (state, action) => {
      state.standards = action.payload.standards;
    },
    setInstruments: (state, action) => {
      state.instruments = action.payload.instruments;
    },
    setDisciplines: (state, action) => {
      state.disciplines = action.payload.disciplines;
    },
    setClients: (state, action) => {
      state.clients = action.payload.clients;
    },
    setUnits: (state, action) => {
      state.units = action.payload.units;
    },
    setUncertainties: (state, action) => {
      state.uncertainties = action.payload.uncertainties;
    },
    setSettings: (state, action) => {
      state.settings = action.payload.settings;
    },
    setUser: (state, action) => {
      state.users = action.payload.users;
    },
  },
});

export const {
  setStandrds,
  setInstruments,
  setDisciplines,
  setClients,
  setUnits,
  setUncertainties,
  setSettings,
  setUser,
} = globalDataSlice.actions;

// export const getLoginInfo = (state) => state.login;
export const getStandards = (state) => state.globalData.standards;
export const getInstruments = (state) => state.globalData.instruments;
export const getDisciplines = (state) => state.globalData.disciplines;
export const getClients = (state) => state.globalData.clients;
export const getUnits = (state) => state.globalData.units;
export const getUncertainties = (state) => state.globalData.uncertainties;
export const getSettings = (state) => state.globalData.settings;
export const getUser = (state) => state.globalData.users;

export default globalDataSlice.reducer;
