var metric
  , imperial;

metric = {
  Nm: {
    name: {
      singular: 'Newton-meter'
    , plural: 'Newton-meters'
    }
  , to_anchor: 1
  }
, kgfM: {
    name: {
      singular: 'Kilogram-force Meter'
    , plural: 'Kilogram-force Meters'
    }
  , to_anchor: 9.80665
  }
};

imperial = {
  'ftlb': {
    name: {
      singular: 'Foot-pound'
    , plural: 'Foot-pounds'
    }
  , to_anchor: 1
  }
, inlb: {
    name: {
      singular: 'Inch-pound'
    , plural: 'Inch-pounds'
    }
  , to_anchor: 1/12
  }
};

module.exports = {
  metric: metric
, imperial: imperial
, _anchors: {
    metric: {
      unit: 'Nm'
    , ratio: 1/0.737562
    }
  , imperial: {
      unit: 'ftlb'
    , ratio: 0.737562
    }
  }
};
