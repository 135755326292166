import { BASE_URL } from "../global";
import axiosWithToken from "./components/axiosTokenConfig";

export const getUsers = async (type, process) => {
  let query =
    BASE_URL +
    `users?_where=(status,eq,1)${type ? `~and(type,eq,${type})` : ""}`;
  return axiosWithToken.get(query).then((res) => {
    if (process) return process(res);
    return res;
  });
};
