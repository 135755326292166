import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectTypeA } from "../../typeA/typeASlice";

import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Chip,
} from "@mui/material";
import unitConvertor from "../../../utils/components/unitConvertor";

import { useSnackbar } from "notistack";

import { ClassicTable } from "../../../utils/components/Styles";
import { useReactToPrint } from "react-to-print";
import { BASE_URL } from "../../../global";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { resolveSource } from "../utils";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

export default function EditBudget(props) {
  let {
    instrumentId,
    lc,
    datasheetId,
    nominalVal,
    datasheetReadingId,
    stdRangeIds,
    tableId,
    supportiveMaster,
    unitRow,
    ucUnit,
  } = useParams();

  if (props.viewAll == true) {
    datasheetId = props.datasheetId;
    instrumentId = props.instrumentId;
    lc = props.lc;
    nominalVal = props.nominalVal;
    datasheetReadingId = props.datasheetReadingId;
    stdRangeIds = props.stdRangeIds;
    supportiveMaster = props.supportiveMaster;
    unitRow = props.unitRow;
    ucUnit = props.ucUnit;
  }

  stdRangeIds = [[...(stdRangeIds || "").split("_")], [], [], {}];
  stdRangeIds[0].map((stdR) => {
    stdRangeIds[1].push(stdR.split(":")[0]);
    stdRangeIds[2].push(stdR.split(":")[1]);
  });
  stdRangeIds[1].map((r, i) => (stdRangeIds[3][r] = stdRangeIds[2][i]));
  delete stdRangeIds[0];

  const typeAValues = useSelector(selectTypeA);
  const [resultCmc, setResultCmc] = useState("");
  const [rangeCol, setRanegCol] = useState(null);

  const [stdDevOfMean, setStdDevOfMean] = useState(0);
  const [meanOfStdDev, setMeanOfStdDev] = useState(0);
  const [customValues, setCustomValues] = useState(null);
  const [standardIds, setStandardIds] = useState([]);
  const [subStandardIds, setSubStandardIds] = useState([]);
  const [standards, setStandards] = useState(null);
  const [uncertaintyFactors, setUncertaintyFactors] = useState(null);
  const [filteredUncertaintyFactors, setFilteredUncertaintyFactors] = useState(
    []
  );
  const [uncertaintyFactorsMap, setUncertaintyFactorsMap] = useState([]);
  const [supportiveInstruments, setSupportiveInstruments] = useState(null);
  const [ucData, setUcData] = useState({
    standards: null,
    supportives: null,
  });
  const [uc, setUc] = useState(0);
  const [freedom, setFreedom] = useState(0);
  const [uncertainty, setUncertainty] = useState(0);
  const [uncertaintyPercentage, setUncertaintyPercentage] = useState(null);
  const [fieldRows, setFieldRows] = useState({
    standardRows: null,
    supportiveRows: null,
  });
  const [typeA, setTypeA] = useState(null);

  const printComponentRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [derivedValues, setDerivedValues] = useState(null);
  const [datasheetReading, setDatasheetReading] = useState({});
  const [datasheetStaticTable, setDatasheetStaticTable] = useState(null);

  const [staticTables, setStaticTables] = useState(null);
  const [customRels, setCustomRels] = useState(null);
  const [staticReading, setStaticReadings] = useState(null);
  const [masters, setMasters] = useState(null);
  const [supportive, setSupportive] = useState([]);
  const [cmcs, setCmcs] = useState(null);
  const [units, setUnits] = useState([]);
  const [range, setRange] = useState(0);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const parseIds = (ids) => {
    ids = ids.split(",");
    let sids = [];
    let subsids = [];
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i].split(":");
      sids.push(id[0]);
      subsids.push(id[1]);
    }
    setStandardIds(sids);
    setSubStandardIds(subsids);
  };

  // api calls
  function fetchTypeB() {
    axiosWithToken
      .get(
        BASE_URL + `typeB?_where=(datasheetReadingId,eq,${datasheetReadingId})`
      )
      .then((res) => {
        if (res.data?.length > 0) {
          setCustomValues(JSON.parse(res.data[0].customValues));
        } else {
          setCustomValues({});
        }
      })
      .catch((err) => {
        console.error("typeB data fetching error: ", err);
      });
  }

  function fetchTypeA() {
    axiosWithToken
      .get(
        BASE_URL + `typeA?_where=(datasheetReadingId,eq,${datasheetReadingId})`
      )
      .then((res) => {
        if (res.data?.length > 0) {
          setTypeA(res.data[0]);
        }
      })
      .catch((err) => {
        console.error("typeB data fetching error: ", err);
      });
  }

  function fetchDatasheetReading() {
    axiosWithToken
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(id,in,${datasheetReadingId}${
            unitRow && unitRow != -1 ? `,${unitRow}` : ""
          })`
      )
      .then((res) => {
        let d = [[], []];
        res.data.map((data) => {
          if (data["c1"].includes("_unit_")) {
            d[0] = data;
          } else {
            d[1] = data;
          }
        });
        setUnits([d[0]]);
        setDerivedValues(JSON.parse(d[1]?.typeBValues));
        setDatasheetReading(d[1]);
        setStaticReadings(d[1]);
      })
      .catch((err) => {
        console.error("datasheet data fetching error: ", err);
      });
  }

  function fetchInstrument(additionalStandardIds) {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        let uMap = {};
        let master = "";
        let uArr = [];
        let nMap = {};
        res.data[0].uncertaintyFactors.split("|").map((um) => {
          master = um.split(":")[0];
          uArr = um.split(":")[1].split(",");
          uArr.map((u) => {
            if (nMap[u]) nMap[u].push(master);
            else nMap[u] = [master];
          });
        });
        setUncertaintyFactorsMap({ ...nMap });
        parseIds(additionalStandardIds || res.data[0].standardMasterIds);
        fetchUncertaintyFactors(
          res.data[0].uncertaintyFactors
            .split("|")
            .map((e) => e.split(":")[1])
            .join(",")
        );
      })
      .catch((err) => {
        console.error("instrument data fetching error: ", err);
      });
  }

  function fetchDatasheet() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `datasheets?_where=(id,eq,${datasheetId})`)
      .then((res) => {
        fetchInstrument(res.data[0].additionalStandardIds);
        fetchUncertaintyFactors(res.data[0].uncertaintyFactors);
      })
      .catch((err) => {
        console.error("instrument data fetching error: ", err);
      });
  }

  function fetchDatasheetStaticTables() {
    let url = BASE_URL;
    return axiosWithToken
      .get(
        url + `datasheetStaticTables?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        setStaticTables(res.data.length > 0 ? res.data[0] : {});
        return res;
      })
      .catch((err) => {
        console.error("datasheet static tables data fetching error: ", err);
        return err;
      });
  }

  function fetchStandards() {
    let url = BASE_URL;
    axiosWithToken
      .get(
        BASE_URL +
          `xjoin?_join=s.standards,_j,sr.standardRanges&_on1=(s.id,eq,sr.standardId)&_fields=s.standardName,sr.id,s.id&_where=(s.status,eq,1)~and(sr.id,in,${stdRangeIds[2]})`
      )
      .then((res) => {
        setStandards(res.data ? [...res.data] : []);
      })
      .catch((err) => {
        console.error("standards data fetching error: ", err);
      });
  }

  function fetchSupportiveInstruments(supportiveInstrumentIds) {
    supportiveInstrumentIds = supportiveMaster?.split(",");
    let ids = [];
    let subids = [];
    for (let i = 0; i < supportiveInstrumentIds?.length; i++) {
      let id = supportiveInstrumentIds[i].split(":");
      ids.push(id[0]);
      subids.push(id[1]);
    }

    axiosWithToken
      .get(BASE_URL + `standardRanges?_where=(id,in,${subids})`)
      .then((res) => {
        setSupportiveInstruments(res.data ? [...res.data] : []);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });

    axiosWithToken
      .get(
        BASE_URL +
          `standards?_where=(id,in,${ids?.toString()})&_fields=standardName,id`
      )
      .then((res) => {
        let ss = {};
        res.data.map((s) => (ss[s.id] = s.standardName));
        setSupportive(ss);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });
  }

  const fetchStandardMasters = () => {
    axiosWithToken
      .get(
        BASE_URL +
          `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.id,pl.id&_where=(pl.status,eq,1)~and(pr.id,in,${stdRangeIds[2]})`
      )
      .then((res) => {
        let ms = {};
        res.data.map((m) => (ms[m.pr_id] = m));
        setMasters(ms);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });
  };

  function fetchUncertaintyFactors(uncertaintyFactors) {
    axiosWithToken
      .get(
        BASE_URL +
          `uncertainty?_where=(id,in,${uncertaintyFactors?.toString()})`
      )
      .then((res) => {
        setFilteredUncertaintyFactors(res.data ? [...res.data] : []);
      })
      .catch((err) => {
        console.error("uncertainty factors data fetching error: ", err);
      });
  }

  function fetchStaticTable() {
    axiosWithToken
      .get(
        BASE_URL +
          `datasheetStaticTables?_where=(id,eq,${tableId.split("__")[0]})`
      )
      .then((res) => {
        setDatasheetStaticTable(res?.data[0]);
      })
      .catch((err) => {
        console.error("datasheet static table data fetching error: ", err);
      });
  }

  const updateUncertainy = (uncertainty) => {
    axiosWithToken
      .patch(BASE_URL + `/datasheetStaticReadings/${datasheetReadingId}`, {
        uncertainty: uncertaintyPercentage
          ? uncertaintyPercentage
          : uncertainty,
      })
      .then((res) => {})
      .catch((err) => {
        console.error("uncertainty update error: ", err);
      });
    axiosWithToken
      .patch(BASE_URL + `/certificateStaticReadings/${datasheetReadingId}`, {
        uncertainty: uncertaintyPercentage
          ? uncertaintyPercentage
          : uncertainty,
      })
      .then((res) => {})
      .catch((err) => {
        console.error("uncertainty update error: ", err);
      });
  };

  const processStandardInstrument = async () => {
    let rows = [];
    let _ucData = [];
    ucUnit = ucUnit?.split("_unit_")?.[1] || "";
    // get m1 unit
    let m1Unit = String(stdDevOfMean)?.split("#")[1];
    ucUnit = ucUnit != "%" ? ucUnit : m1Unit ? m1Unit : "";

    for (let index = 0; index < standards.length; index++) {
      for (let i = 0; i < filteredUncertaintyFactors?.length; i++) {
        if (
          !uncertaintyFactorsMap[filteredUncertaintyFactors[i].id].includes(
            `${standards[index].s_id}`
          )
        )
          continue;
        // unlinked factors should display only once
        let selectors = {
          datasheets: datasheetId,
          instruments: instrumentId,
          datasheetStaticReadings: datasheetReadingId,
          standards: standards[index].s_id,
          standardRanges: standards[index].sr_id,
        };

        let actualVal = await resolveSource(
          JSON.parse(filteredUncertaintyFactors[i].sourceconfig || null),
          selectors,
          ucUnit
        );

        if (customValues?.["s:" + index + ":" + (i + 1)]) {
          actualVal = customValues["s:" + index + ":" + (i + 1)];
        }

        if (customRels?.[filteredUncertaintyFactors[i].id]) {
          actualVal = customRels[filteredUncertaintyFactors[i].id][0];
        }

        let val = actualVal ? actualVal : 0;

        let processedValue =
          customRels[filteredUncertaintyFactors[i].id] ||
          filteredUncertaintyFactors[i].name
            .toLowerCase()
            .trim()
            .includes("resolution")
            ? customRels[filteredUncertaintyFactors[i].id]?.[1]
                .toLowerCase()
                .trim()
                .includes("leastcount") ||
              customRels[filteredUncertaintyFactors[i].id]?.[1]
                .toLowerCase()
                .includes("least count") ||
              filteredUncertaintyFactors[i].name
                .toLowerCase()
                .trim()
                .includes("resolution")
              ? actualVal / 2
              : actualVal
            : actualVal;

        let formula = Number(filteredUncertaintyFactors[i]?.formula);
        if (formula) {
          val = String(processedValue)?.split("#")[0];
          val = (Number(val) / formula).toFixed(4);
        }
        _ucData.push(val);

        val = String(val)?.split("#")[0];
        actualVal = String(actualVal)?.split("#")[0];
        processedValue = String(processedValue)?.split("#")[0];

        let row = [
          `${filteredUncertaintyFactors[i].name}`,
          actualVal + "#" + ucUnit,
          processedValue + "#" + ucUnit,
          filteredUncertaintyFactors[i].distribution,
          val + "#" + ucUnit,
          1,
          val + "#" + ucUnit,
          "∞",
        ];
        rows.push(row);
      }
    }
    return [_ucData, rows];
  };

  const processSupportiveInstrument = () => {
    let rows = [];
    let parameter = ["axialUniformity", "radialUniformity", "stability"];
    let _ucData = [];
    supportiveInstruments.length > 0 &&
      supportiveInstruments.map((factor, index) => {
        for (let i = 0; i < parameter.length; i++) {
          if (factor[parameter[i]] != "" && factor[parameter[i]] != null) {
            let dv = factor[parameter[i]];
            if (customValues["si:" + index + ":" + (i + 1)]) {
              dv = customValues["si:" + index + ":" + (i + 1)];
            }
            let value = (
              (dv?.match(/[\d\.]+/g) ? dv?.match(/[\d\.]+/g)[0] : 0) /
              1.7320508075688772
            ).toFixed(4); // dividing by root 3
            _ucData.push(value);
            let row = [
              parameter[i],
              dv,
              dv,
              "1/√3",
              value,
              1,
              value,
              "∞",
              factor.standardId,
            ];
            rows.push(row);
          }
        }
      });
    return [_ucData, rows];
  };

  const resolveFormula = (column, uncertainty) => {
    let _uncertainty = null;
    if (datasheetStaticTable) {
      let defaultConfig = JSON.parse(
        datasheetStaticTable["defaultConfiguration"] || {}
      );
      let formulas = defaultConfig["budget"];
      Object.keys(formulas || {}).forEach((formula) => {
        let _formula = formulas[formula]["uncertainty"]?.["default"];
        if (_formula) {
          for (let i = 30; i > 0; i--) {
            let value = datasheetReading["c" + i]?.split("#")[0];
            _formula = _formula.replaceAll("c" + i, value);
          }

          if (_formula.includes("uncertainty")) {
            _formula = _formula.replaceAll("uncertainty", uncertainty);
            _uncertainty = eval(_formula);
          }
        }
      });
    }
    return _uncertainty;
  };

  const calcUncertainty = () => {
    // calc uc
    let _meanOfStdDev = String(meanOfStdDev)?.split("#")[0];
    let rept = Number(_meanOfStdDev ? _meanOfStdDev : 0);
    console.log({ rept });

    let k = 2.0;
    let sum = 0;
    ucData.standards?.forEach((element) => {
      sum += Number(element) * Number(element);
    });

    ucData.supportives?.forEach((element) => {
      sum += Number(element) * Number(element);
    });
    sum += rept * rept;
    let res = Number(Math.sqrt(sum).toFixed(4));
    setUc(res);

    let uncertainty = (res * k).toFixed(6);
    let uncertainty_percentage = resolveFormula("uncertainty", uncertainty);
    setUncertaintyPercentage(
      uncertainty_percentage ? Number(uncertainty_percentage).toFixed(6) : null
    );
    setUncertainty(uncertainty);

    uncertainty = uncertainty_percentage || uncertainty;
    if (uncertainty !== undefined && staticReading) {
      let cSet = [
        null,
        "",
        null,
        "",
        Number(staticReading[rangeCol]?.split("#")[0]),
        ((units.length ? units[0][rangeCol] : "") || "").replaceAll(
          "_unit_",
          ""
        ),
        null,
        "",
        null,
        "",
      ];
      for (let i = 0; i < cmcs.length; i++) {
        if (
          cmcs[i]["fromRange"].split("#")[0] &&
          cmcs[i]["fromRange"].split("#")[0].trim() !== ""
        ) {
          cSet[0] = cmcs[i]["fromRange"].split("#");
          cSet[1] = cSet[0][1];
          cSet[0] = Number(cSet[0][0]);
          try {
            cSet[0] = unitConvertor(cSet[0], cSet[1], cSet[5]);
          } catch {
            cSet[1] = "";
          }
        }
        if (
          cmcs[i]["toRange"].split("#")[0] &&
          cmcs[i]["toRange"].split("#")[0].trim() !== ""
        ) {
          cSet[2] = cmcs[i]["toRange"].split("#");
          cSet[3] = cSet[2][1];
          cSet[2] = Number(cSet[2][0]);
          try {
            cSet[2] = unitConvertor(cSet[2], cSet[3], cSet[5]);
          } catch {
            cSet[3] = "";
          }
        }
        if (cSet[0] <= cSet[4] && cSet[2] >= cSet[4]) {
          if (
            cmcs[i]["lowerCmc"].split("#")[0] &&
            cmcs[i]["lowerCmc"].split("#")[0].trim() !== ""
          ) {
            cSet[6] = cmcs[i]["lowerCmc"].split("#");
            cSet[7] = cSet[6][1];
            cSet[6] = Number(cSet[6][0]);
            try {
              cSet[6] = unitConvertor(cSet[6], cSet[7], cSet[5]);
            } catch {
              cSet[7] = "";
            }
          }

          if (
            cmcs[i]["higherCmc"].split("#")[0] &&
            cmcs[i]["higherCmc"].split("#")[0].trim() !== ""
          ) {
            cSet[8] = cmcs[i]["higherCmc"].split("#");
            cSet[9] = cSet[8][1];
            cSet[8] = Number(cSet[8][0]);
            try {
              cSet[8] = unitConvertor(cSet[8], cSet[9], cSet[5]);
            } catch {
              cSet[9] = "";
            }
          }
          if (
            cSet[8] &&
            (typeof cSet[8] === "string" ? cSet[8].trim() !== "" : true)
          ) {
            if (cSet[8] < uncertainty) {
              setResultCmc(`${uncertainty}`);
            } else {
              setResultCmc(
                cmcs[i]["lowerCmc"].replaceAll("#", " ") ||
                  cmcs[i]["higherCmc"].replaceAll("#", " ")
              );
              uncertainty = cSet[6] || cSet[8];
            }
            break;
          } else {
            if (cSet[6] < uncertainty) {
              setResultCmc(`${uncertainty}`);
            } else {
              setResultCmc(cmcs[i]["lowerCmc"].replaceAll("#", " "));
              uncertainty = cSet[6];
            }
            break;
          }
        }
      }
    }

    setFreedom(
      ((Math.pow(0.440820639 * 2, 4) * range) / Math.pow(0.012, 4)).toFixed(6)
    );

    updateUncertainy(uncertainty);
  };

  const getCmcReadings = () => {
    axiosWithToken
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${instrumentId})`)
      .then((res) => {
        setCmcs(res.data ? res.data : []);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong while fetching standardRanges!");
        }
      });
  };

  React.useEffect(() => {
    fetchTypeB();
    fetchDatasheet();
    fetchTypeA();
    fetchDatasheetReading();
    fetchStaticTable();
    fetchDatasheetStaticTables();
    fetchStandardMasters();
    fetchSupportiveInstruments();
  }, []);

  React.useEffect(() => {
    if (stdDevOfMean != null && typeA != null) {
      setStdDevOfMean(typeA.stdDevOfMean);
      setMeanOfStdDev(typeA.meanOfStdDev);
      setRange(typeA.degreesOfFreedom);
    }
  }, [typeA, stdDevOfMean]);

  React.useEffect(() => {
    if (typeAValues.value) {
      let stdDevOfMean = typeAValues?.value[datasheetReadingId]?.stdDevOfMean;
      let meanOfStdDev = typeAValues?.value[datasheetReadingId]?.meanOfStdDev;
      setStdDevOfMean(stdDevOfMean);
      setMeanOfStdDev(meanOfStdDev);
    }
  }, [typeAValues.value, datasheetReadingId]);

  React.useEffect(() => {
    if (standardIds?.length > 0 && subStandardIds?.length > 0) fetchStandards();
  }, [standardIds, subStandardIds]);

  const processFactors = async () => {
    let res = await processStandardInstrument();
    let data = [res, processSupportiveInstrument()];
    setUcData({
      ...ucData,
      standards: [...data[0][0]],
      supportives: [...data[1][0]],
    });
    setFieldRows({
      ...fieldRows,
      standardRows: [...data[0][1]],
      supportiveRows: [...data[1][1]],
    });
  };
  React.useEffect(() => {
    if (
      filteredUncertaintyFactors !== null &&
      standards !== null &&
      customRels !== null &&
      supportiveInstruments !== null &&
      customValues !== null &&
      masters !== null
    ) {
      processFactors();
    }
  }, [
    standards,
    filteredUncertaintyFactors,
    customRels,
    customValues,
    supportiveInstruments,
    masters,
  ]);

  React.useEffect(() => {
    if (
      ucData.standards !== null &&
      ucData.supportives !== null &&
      staticReading !== null &&
      units !== null &&
      cmcs != null &&
      rangeCol !== null
    ) {
      calcUncertainty();
    }
  }, [ucData, units, rangeCol, staticReading, cmcs]);

  React.useEffect(() => {
    if (staticTables && staticReading && filteredUncertaintyFactors !== null) {
      let ops = {};
      (filteredUncertaintyFactors || []).map((op) => ops[op.id]);
      let rels = JSON.parse(staticTables.defaultConfiguration);
      setRanegCol(rels.rangeCol ? rels.rangeCol : "");
      rels =
        rels && rels.typeb && rels.typeb.relations
          ? Object.entries(rels.typeb.relations)
          : [];
      let newRels = {};
      rels.map((rel) => {
        newRels[rel[1]] = [staticReading[rel[0]], staticTables[rel[0]]];
      });
      setCustomRels(newRels);
    }
  }, [staticReading, staticTables, filteredUncertaintyFactors]);

  React.useEffect(() => {
    getCmcReadings();
  }, [staticTables, datasheetStaticTable]);

  const renderFieldRows = () => {
    let rows = [];
    let uCount = 1;
    fieldRows.standardRows?.forEach((row) => {
      rows.push(
        <TableRow key="1">
          <TableCell>
            {"U" + uCount}
            <br />
            {`${row[0]}`}
          </TableCell>
          {row.slice(1, row.length).map((cell) => (
            <TableCell>{String(cell)?.replaceAll("#", "")}</TableCell>
          ))}
        </TableRow>
      );
      uCount++;
    });
    fieldRows.supportiveRows?.forEach((row) => {
      rows.push(
        <TableRow key="1">
          <TableCell>
            {"U" + uCount}
            <br />
            {`${row[0]} (${supportive[row[row.length - 1]]})`}
          </TableCell>
          {row.slice(1, row.length - 1).map((cell) => (
            <TableCell>{String(cell)?.replaceAll("#", "")}</TableCell>
          ))}
        </TableRow>
      );
      uCount++;
    });

    // fixed rows
    rows.push(
      <TableRow key="1">
        <TableCell>Repeatability</TableCell>
        <TableCell>{String(stdDevOfMean)?.replace("#", "")}</TableCell>
        <TableCell>{String(stdDevOfMean)?.replace("#", "")}</TableCell>
        <TableCell>√{Number(range) + 1}</TableCell>
        <TableCell>{String(meanOfStdDev)?.replace("#", "")}</TableCell>
        <TableCell>1</TableCell>
        <TableCell>{String(meanOfStdDev)?.replace("#", "")}</TableCell>
        <TableCell>{range}</TableCell>
      </TableRow>
    );
    rows.push(
      <TableRow key="1">
        <TableCell></TableCell>
        <TableCell>Uc</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>{uc}</TableCell>
        <TableCell>{freedom}</TableCell>
      </TableRow>
    );
    rows.push(
      <TableRow key="1">
        <TableCell></TableCell>
        <TableCell>Expanded Uncertainty</TableCell>
        <TableCell>K=</TableCell>
        <TableCell>2</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>{(uc * 2).toFixed(4)}</TableCell>
        <TableCell>∞</TableCell>
      </TableRow>
    );

    return rows;
  };

  return (
    <Paper sx={{ mx: 5, mt: 2, p: 2 }} ref={printComponentRef}>
      <Typography variant="h5" align="center" component="div" sx={{ mb: 2 }}>
        Uncertainty Budget
      </Typography>
      <div>
        {meanOfStdDev == null && (
          <p style={{ color: "red" }}>please verifiy Type-A</p>
        )}
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sources of Uncertainty</TableCell>
                <TableCell>Estimate</TableCell>
                <TableCell>Limits Xi</TableCell>
                <TableCell>Probability Distribution</TableCell>
                <TableCell>Standard Uncertainty</TableCell>
                <TableCell>Sensitivity Coefficient</TableCell>
                <TableCell>Uncertainty Contribution </TableCell>
                <TableCell>Degrees of freedom</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderFieldRows()}</TableBody>
          </Table>
        </ClassicTable>
      </div>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="COMBINED UNCERTAINTY (Uc)"
            value={uc}
            size="small"
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="EFFECTIVE DEGREES OF FREEDOM"
            value={freedom}
            size="small"
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="EXPANDED UNCERTAINTY"
            value={uncertainty}
            size="small"
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>
        {uncertaintyPercentage && (
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="EXPANDED UNCERTAINTY (%)"
              value={uncertaintyPercentage}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
        )}
        {uncertainty && (
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="RESULT CMC (%)"
              value={resultCmc}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }} style={{ marginTop: "80px" }}>
        <Grid item xs={4}>
          <Typography align="center">Calibrated By</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Typography align="center">Approved By</Typography>
        </Grid>
      </Grid>{" "}
      <Divider sx={{ mb: 1, mt: 2, displayPrint: "none" }}></Divider>
      <Toolbar
        sx={{ displayPrint: "none" }}
        style={{ padding: "0px", width: "100%" }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, displayPrint: "none" }}
          onClick={() => {
            enqueueSnackbar("Budget updated successfully !", {
              variant: "success",
            });
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3, displayPrint: "none" }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Toolbar>
    </Paper>
  );
}
