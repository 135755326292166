import { BASE_URL } from "./global";
import axiosWithToken from "./utils/components/axiosTokenConfig";

const api = "xmysql";
const requests = {
  get: async (query, input) =>
    await axiosWithToken.get(query).then((res) => res.data),
  post: async (query, input) =>
    await axiosWithToken.post(query, input.params.data).then((res) => res.data),
  patch: async (query, input) =>
    await axiosWithToken
      .patch(query, input.params.data)
      .then((res) => res.data),
  delete: async (query) =>
    await axiosWithToken.patch(query).then((res) => res.data),
};

export const request = async (input) => {
  let url = points[api][input.url];
  let query =
    (input.dynamic ? "" : BASE_URL) +
    (input.params && typeof url !== "string" ? url(input.params) : url);
  if (input.dynamic) {
    input.params.data = { query: query };
    query = BASE_URL + "dynamic";
  }
  let data = await requests[input.type](query, input);
  return data;
};

const points = {
  xmysql: {
    getStandards: "standards?_where=(status,eq,1)",
    getInstruments: "instruments?_where=(status,eq,1)",
    getDisciplines: "discipline?_where=(status,eq,1)",
    getClients: "clients?_where=(status,eq,1)",
    createClient: "clients",
    getUnits: "unit?_where=(status,eq,1)",
    getUncertainties: "uncertainty?_where=(status,eq,1)",
    getSettings: "settings?_where=(status,eq,1)",
    getUsers: "users?_where=(status,eq,1)",
    getEnquiry: (params) =>
      `xjoin?_join=en.enquiry,_j,client.clients&_on1=(en.clientID,eq,client.id)&_fields=en.contactPersonName,en.emailId,en.address,en.followUpDate,en.date,en.source,en.leadStatus,en.requirements,en.leadFollowup,en.rating,client.companyName,client.address,client.id&_where=(en.id,eq,${params.id})`,
    getEnquiryTable: (params) =>
      `select client.companyName,en.contactPersonName,en.emailId,en.followUpDate,en.date,en.source,en.rating,en.id,en.leadStatus,en.createdby,client.address,client.contact from enquiry as en inner join clients as client on en.clientId = client.id ${params.whereConditions} and en.status=1 order by en.id DESC ${params.pagination_settings}`,
    getEnquiryTable2: (params) =>
      `xjoin?_join=en.enquiry,_j,client.clients&_on1=(en.clientId,eq,client.id)&_fields=client.companyName,en.contactPersonName,en.emailId,en.followUpDate,en.date,en.source,en.rating,en.id,en.leadStatus,en.createdby,client.address,client.contact&_sort=-en.id&_where=(en.status,eq,1)${params.whereConditions2}${params.pagination_settings2}`,
    getEnquiryTableCount: (params) =>
      `select count(client.companyName) as no_of_rows from enquiry as en inner join clients as client on en.clientId = client.id where en.status = 1 ${params.whereConditions} order by en.id DESC ${params.pagination_settings}`,
    updateEnquiry: (params) => `enquiry/${params.id}`,
    createEnquiry: "enquiry",
    deleteEnquiry: (params) => `enquiry/${params.id}`,
  },
};
