
import { BASE_URL } from "../../global";
import axiosWithToken from "../components/axiosTokenConfig";

export async function countOfCertificatesAnalytics(ids) {
  try {
    for (const id of ids) {
      const url = `${BASE_URL}dynamic`;

      const updateQueryPayload = {
        query: `UPDATE certificateAnalytics SET certificateDownloadCounter = certificateDownloadCounter + 1 WHERE certificateId = ${id}`,
      };
      const Res = await axiosWithToken.post(url, updateQueryPayload);

      if (Res?.data?.affectedRows === 0) {
        const insertQueryPayload = {
          query: `INSERT INTO certificateAnalytics (certificateId) VALUES (${id})`,
        };
        await axiosWithToken.post(url, insertQueryPayload);
      }
    }
  } catch (error) {
    console.error("Error creating/updating certificate Analytics:", error);
  }
}

export async function countOfStickerAnalytics(ids) {
  try {
    for (const id of ids) {
      const url = `${BASE_URL}dynamic`;
      const countQueryPayload = {
        query: `SELECT COUNT(*) FROM certificateAnalytics WHERE certificateId = ${id};`,
      };

      const response = await axiosWithToken.post(url, countQueryPayload);
      const count = response.data?.[0]?.["COUNT(*)"];

      if (count === 0) {
        const insertQueryPayload = {
          query: `INSERT INTO certificateAnalytics (certificateId) VALUES (${id})`,
        };
        await axiosWithToken.post(url, insertQueryPayload);
      }

      const updateQueryPayload = {
        query: `UPDATE certificateAnalytics SET stickerDownloadCounter = stickerDownloadCounter + 1 WHERE certificateId = ${id}`,
      };
      await axiosWithToken.post(url, updateQueryPayload);
    }
  } catch (error) {
    console.error("Error creating/updating certificate Analytics:", error);
  }
}
