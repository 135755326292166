import { driver } from "driver.js";
export const certificateListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this certificate list.",
        },
      },
      {
        element: "#certificate-certificatelistdatefrom",
        popover: {
          title: "Select Date",
          description: "Select start date.",
        },
      },
      {
        element: "#certificate-certificatelistdateto",
        popover: {
          title: "Select Date",
          description: "Select end date.",
        },
      },
      {
        element: "#certificate-certificatelistsearchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#certificate-certificatelistsearchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When you select the previous dropdown value, enter your value for the search.",
        },
      },
      {
        element: "#certificate-certificatelistsearchbtn",
        popover: {
          title: "Search ",
          description:
            "When you select the previous dropdown or enter a value, click this button to get the result.",
        },
      },
      {
        element: "#certificate-certificatelistprintall",
        popover: {
          title: "Print All Certificates",
          description:
            "Below the list, you can print all certificates at once.",
        },
      },
      {
        element: "#certificate-certificatelisttable",
        popover: {
          title: "Certificate Table",
          description:
            "Certificate table where you can view and download certificates.",
        },
      },
      {
        element: "#certificate-certificatelistprint",
        popover: {
          title: "Print Button",
          description:
            "Click this button to view and download the certificate.",
        },
      },
      {
        element: "#certificate-certificateliststiker",
        popover: {
          title: "Sticker",
          description: "Click this button to open the sticker modal.",
        },
      },
      {
        element: "#certificate-certificatelistselectcheckbox",
        popover: {
          title: "Select Multiple Certificates",
          description:
            "When you click this checkbox, table hide options show where you can download multiple certificates at a time.",
        },
      },
      {
        element: "#certificate-certificatelistletterhead",
        popover: {
          title: "Select Letterhead",
          description:
            "Select letterhead with or without (When you select the certificate checkbox).",
        },
      },
      {
        element: "#certificate-certificatelistelectronicsign",
        popover: {
          title: "Select Electronic Sign",
          description:
            "Select Electronic Sign with or without (When you select the certificate checkbox).",
        },
      },
      {
        element: "#certificate-certificatelistprintallcertificate",
        popover: {
          title: "Selected Certificate Print",
          description:
            "Selected Certificate Print (When you select the certificate checkbox).",
        },
      },
      {
        element: "#certificate-certificatelistmultipleqr",
        popover: {
          title: "Selected Certificate Print QR",
          description:
            "Selected Certificate Print QR (When you select the certificate checkbox).",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can view certificates.",
        },
      },
    ],
  });
};

export const viewCertificateGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this view certificate.",
        },
      },
      {
        element: "#certificate-certificateviewletterhead",
        popover: {
          title: "Select Letterhead",
          description:
            "Select letterhead with or without (this option appears when all roles have approved the certificate).",
        },
      },
      {
        element: "#certificate-certificateviewelectronicsign",
        popover: {
          title: "Select Electronic Sign",
          description:
            "Select electronic sign with or without (this option appears when all roles have approved the certificate).",
        },
      },
      {
        element: "#certificate-certificateviewDownalod",
        popover: {
          title: "Download Certificate",
          description:
            "Clicking this button allows you to download the certificate (this button appears when all roles have approved the certificate).",
        },
      },
      {
        element: "#certificate-certificateview_reviewbtn",
        popover: {
          title: "Review Button",
          description:
            "Click this button if your role is to review the certificate (this button is hidden when all roles have approved the certificate).",
        },
      },
      {
        element: "#certificate-certificateview_signbtn",
        popover: {
          title: "Sign Button",
          description:
            "Click this button if your role is to sign the certificate (this button is hidden when all roles have approved the certificate).",
        },
      },
      {
        element: "#certificate-certificateview_Witnessedbtn",
        popover: {
          title: "Witnessed Button",
          description:
            "Click this button if your role is to witness the certificate (this button is hidden when all roles have approved the certificate).",
        },
      },
      {
        element: "#certificate-certificateview_Rejectbtn",
        popover: {
          title: "Reject Button",
          description:
            "Click this button if your role is to reject the certificate (this button is hidden when all roles have approved the certificate).",
        },
      },
      {
        element: "#certificate-certificateview_Approvebtn",
        popover: {
          title: "Approve Button",
          description:
            "Click this button if your role is to approve the certificate (this button is hidden when all roles have approved the certificate).",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can view and download the certificate.",
        },
      },
    ],
  });
};

export const multipleQRCertificateGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this QR.",
        },
      },
      {
        element: "#certificate-multipleqr_selctsize",
        popover: {
          title: "Select Size",
          description: "Select QR code size",
        },
      },
      {
        element: "#certificate-multipleqr_download",
        popover: {
          title: "Download QR Code",
          description:
            "When you click this button, you can download the QR code.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can download the QR.",
        },
      },
    ],
  });
};
