import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";

// import { ClassicTable } from "/../utils/components/Styles";
import { BASE_URL } from "./../../global";
// import { insert, view } from "/../components/common/api";
import { integerPropType } from "@mui/utils";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import axiosWithToken from "../../utils/components/axiosTokenConfig";



export default function StdInOut() {
  const navigate = useNavigate();
  const [standardOut, setStandardOut] = React.useState([]);
  const [standardIn, setStandardIn] = React.useState([]);
  const [overDue, setOverDue] = React.useState([]);

  const getStandardOut = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "standardInOut?_where=(status,eq,1)")
      .then((res) => {
        var startDate = moment(new Date()).format("DD-MM-YYYY");
        var endDate = moment().subtract(0, 'day').format("DD-MM-YYYY");
        var resultProductData = res.data.filter(a => {
          var date = moment(a.outwardDate).format("DD-MM-YYYY");
              return (date >= endDate && date <= startDate);
          });
          setStandardOut(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStandardIn = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "standardInOut?_where=(status,eq,1)")
      .then((res) => {
        var startDate = moment(new Date()).format("DD-MM-YYYY");
        var endDate = moment().subtract(0, 'day').format("DD-MM-YYYY");
        var resultProductData = res.data.filter(a => {
          var date = moment(a.expectedReturnDate).format("DD-MM-YYYY");
              return (date >= endDate && date <= startDate);
          });
          setStandardIn(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getOverDue = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "standardInOut?_where=(status,eq,1)")
      .then((res) => {
        var startDate = moment(new Date()).format("DD-MM-YYYY");
        var endDate = moment().subtract(0, 'day').format("DD-MM-YYYY");
        var resultProductData = res.data.filter(a => {
          var date = moment(a.expectedReturnDate).format("DD-MM-YYYY");
              return (date >= endDate && date <= startDate);
          });
          setOverDue(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStandardOut();
    getStandardIn();
    getOverDue();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      {/* <Grid className="mb-4" container spacing={2}>

        <Grid item xs={2}>
          <TextField
            id="Start Date"
            label="Date From"
            variant="outlined"
            type="date"
            className="mr-2"
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
          // value={fromDate}
          // onChange={(e) =>
          //     // setFromDate(e.target.value)
          // }
          />
        </Grid>


        <Grid item xs={2}>
          <TextField
            id="Start Date"
            label="Date To"
            variant="outlined"
            type="date"
            className="mr-2"
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
          // value={toDate}
          // onChange={(e) =>
          //     / setToDate(e.target.value)
          // }
          />
        </Grid>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            // FilterData();
          }}
          style={{ marginLeft: '10px', height: '40px', marginTop: '1em' }}
        >
          Search
        </Button>
      </Grid> */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Standard out </th>
            <th>Standard In  </th>
            <th>Over-due standard </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{standardOut.length}</td>
            <td>{standardIn.length}</td>
            <td>{overDue.length}</td>
          </tr>
        </tbody>
      </Table>
    </Paper>
  );
}
