import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

export default function SplitButton({
  addDescriptionRow,
  productReadingRows,
  getAllProducts,
  getAllSrfsProducts,
  setproductReadingRows,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const options = [
    "Add New Product",
    "All Remaining Instrument",
  ];

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);

    if (index === 0) {
      addDescriptionRow(0);
    } else if (index === 1) {
      const remainingData = findRemainingData(getAllProducts, getAllSrfsProducts);
      
      const updatedProductReadingRows = mergeDataAndSetState(
        productReadingRows,
        remainingData
      );
      setproductReadingRows(updatedProductReadingRows);
    }
  };

  const findRemainingData = (allProducts, selected) => {
    return selected.map((productA) => {
      const matchingProductIndex = allProducts.findIndex(
        (productB) =>
          productA.equipmentId === productB.equipmentId &&
          parseFloat(productA.rate) === parseFloat(productB.rate)
      );
  
      if (matchingProductIndex !== -1) {
        const matchingProduct = allProducts[matchingProductIndex];
        const updatedProduct = { ...productA };
        updatedProduct.quantity -= matchingProduct.quantity;
        return updatedProduct;
      }
  
      return productA;
    }).filter((product) => product.quantity > 0);
  };

  const mergeDataAndSetState = (previousData, remainingData) => {
    const mergedProducts = Object.values(
      [...previousData, ...remainingData].reduce((acc, obj) => {
        const key = obj.rate + "_" + obj.equipmentId;
        if (acc[key]) {
          acc[key].quantity += obj.quantity;
        } else {
          acc[key] = { ...obj };
        }
        return acc;
      }, {})
    );
    return mergedProducts;
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleClick = () => {
    // if (selectedIndex === 0) {
    addDescriptionRow(0);
    // }
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick}>{options[0]}</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
