var metric, imperial;

metric = {
  lx: {
    name: {
      singular: "Lux",
      plural: "Lux",
    },
    to_anchor: 1,
  },
};

imperial = {
  "ft-cd": {
    name: {
      singular: "Foot-candle",
      plural: "Foot-candles",
    },
    to_anchor: 1,
  },
  "m-cd": {
    name: {
      singular: "Meter-candle",
      plural: "Meter-candles",
    },
    to_anchor: 10.76391,
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "lx",
      ratio: 1 / 10.76391,
    },
    imperial: {
      unit: "ft-cd",
      ratio: 10.76391,
    },
  },
};
