import { createSlice } from "@reduxjs/toolkit";

const initialState = { loggedIn: false };

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logIn: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload.user;
      state.settings = action.payload.settings;
    },
    logOut: (state, action) => {
      delete state.user;
      delete state.settings;
      state.loggedIn = false;
    },
  },
});

export const { logIn, logOut } = loginSlice.actions;
export const getLoginInfo = (state) => state.login;
export const isLoggedin = (state) => state.login.loggedIn;
export const getUserId = (state) => state.login?.user?.id;

export default loginSlice.reducer;
