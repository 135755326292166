import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ErrorModal = ({ errormodalIsOpen, closeErrorModal, errorMessage }) => {
  const match = /at\s+(\S+)\s+\((.+)\)/.exec(errorMessage?.stackTrace);
  let functionName;
  if (match) {
    functionName = match[1];
  }
   else {
    functionName = errorMessage?.stackTrace;
  }

  const copyToClipboard = () => {
    const errorInfo = `Error Message: ${errorMessage?.errorMessage}\nError Function Name: Error at ${functionName}\nError File Name: ${errorMessage?.fileName}\nLink : ${window.location.href}`;

    navigator.clipboard.writeText(errorInfo);

    closeErrorModal();

    toast.success("Error message copied to clipboard", {
      autoClose: 1000,
      position: "top-center",
    });
  };
  return (
    <Modal open={errormodalIsOpen} onClose={closeErrorModal}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 550,
          "@media (max-width: 600px)": {
            width: "80%", // Adjust the width for screens up to 768px wide
          },
          "@media (max-width: 480px)": {
            width: "80%", // Adjust the width for screens up to 480px wide
          },
          backgroundColor: "white",
          border: "2px solid #001E6C",
          boxShadow: 24,
          padding: "20px",
        }}
      >
        <h6 className="mb-2">
          <b>Error Message:</b> {errorMessage?.errorMessage}
        </h6>
        <h6 className="mb-2">
          <b>Error Function Name:</b> Error at {functionName}
        </h6>
        <h6 className="mb-3">
          <b>Error File Name:</b> {errorMessage?.fileName}
        </h6>
        <div className="text-center w-100 my-2">
          <Button
            variant="contained"
            size="small"
            onClick={copyToClipboard}
            endIcon={<ContentCopyIcon />}
          >
            Copy Error
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
