
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

// api calls
export const checkDSStatusByCount = (setDBStatus) => {
  axiosWithToken
    .get(BASE_URL + `datasheets/aggregate?_fields=id`)
    .then(async (res) => {
      // extract max id of DSs
      let ds_max_id = res.data[0]?.max_of_id;
      console.log("ds_max_id", ds_max_id);

      axiosWithToken
        .get(BASE_URL + `certificates/aggregate?_fields=id`)
        .then(async (res) => {
          let cert_max_id = res.data[0]?.max_of_id;
          console.log("cert_max_id", cert_max_id);
          axiosWithToken
            .get(BASE_URL + `srfInstruments/aggregate?_fields=id`)

            .then(async (res) => {
              let srf_ins_max_id = res.data[0]?.max_of_id;
              console.log("srf_ins_max_id", srf_ins_max_id);
              setDBStatus(
                ds_max_id == cert_max_id && ds_max_id == srf_ins_max_id
                  ? "healthy"
                  : "sick"
              );
            })
            .catch((err) => {
              console.log("datasheet data fetching error: ", err);
            });
          setDBStatus(res.data[0]?.max_of_id == ds_max_id ? "healthy" : "sick");
        })
        .catch((err) => {
          console.log("datasheet data fetching error: ", err);
        });
    })
    .catch((err) => {
      console.log("datasheet data fetching error: ", err);
    });
};
