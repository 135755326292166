import { driver } from "driver.js";
export const mastersettinglistGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Setting List.",
        },
      },
      {
        element: "#setting_list_createbtn",
        popover: {
          title: "Create New Setting",
          description: "Click this button to create a new setting.",
        },
      },
      {
        element: "#setting_list_searchby",
        popover: {
          title: "Search Field",
          description: "Using this dropdown, you can search",
        },
      },
      {
        element: "#setting_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When you select a previous dropdown value, then enter your value which you want to search",
        },
      },
      {
        element: "#setting_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "When you select a previous dropdown or enter a value, click on this button to get results",
        },
      },
      {
        element: "#setting_list_table",
        popover: {
          title: "SRF Table",
          description:
            "SRF Details Table where you can edit setting_list_and Download",
        },
      },
      {
        element: "#setting_list_edit",
        popover: {
          title: "Edit Button",
          description: "Click this button to edit the setting",
        },
      },
      {
        element: "#setting_list_delete",
        popover: {
          title: "Delete Button",
          description: "Click this button to delete the setting",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create and manage setting list",
        },
      },
    ],
  });
};

export const mastereditsettingGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in editing/creating a setting.",
        },
      },
      {
        element: "#setting_create_selectkey",
        popover: {
          title: "Select Key",
          description: "Select the key using the dropdown(this only show when yuu first time create setting).",
        },
      },
      {
        element: "#setting_create_keyname",
        popover: {
          title: "Enter Key",
          description: "Enter/Edit Key name",
        },
      },
      {
        element: "#setting_create_keyvalue",
        popover: {
          title: "Enter Key",
          description: "Enter/Edit Key value",
        },
      },
      {
        element: "#setting_create_save",
        popover: {
          title: "Save Setting",
          description: "Click this button to save the setting.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you have successfully created/edited the setting.",
        },
      },
    ],
  });
};
