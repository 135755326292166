function objectsAreEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys and their values are the same
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export default function compareOldAndNewReadings(oldRows, newRows) {
  const differentIds = [];
  newRows.forEach((obj2) => {
    const obj1 = oldRows.find((item) => item.id === obj2.id);
    if (!obj1 || !objectsAreEqual(obj1, obj2)) {
      differentIds.push(obj2.id);
    }
  });
  return differentIds;
}
