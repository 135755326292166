import { driver } from "driver.js";
export const helpmoduleGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Help Section.",
        },
      },
      {
        element: "#help_section_accordion",
        popover: {
          title: "Certifcate Color Code",
          description: "What Color Code used in the certificate list",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can view the Help Section",
        },
      },
    ],
  });
};
