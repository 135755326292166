var metric, imperial;

metric = {
  "%RH": {
    name: {
      singular: "%RH",
      plural: "%RH",
    },
    to_anchor: 1,
  },
};

imperial = {
  "%RH": {
    name: {
      singular: "%RH",
      plural: "%RH",
    },
    to_anchor: 1,
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "rh",
      ratio: 1,
    },
    imperial: {
      unit: "rh",
      ratio: 1,
    },
  },
};
