import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Toolbar,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "./../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";

const textStyle = {
  fontWeight: "bold",
  display: "inline-block",
  minWidth: "120px",
};

const tableStyle = {
  backgroundColor: "#1976d2",
  color: "white",
  fontWeight: "bold",
};

const ViewLogs = () => {
  const location = useLocation();
  const [auditData, setAuditData] = useState([]);
  const [tableDataArray, setTableDataArray] = useState([]);
  const printComponentRef = React.useRef();
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);

  const queryIdArray = new URLSearchParams(location.search).get("id");
  let AuditIDArray = JSON.parse(queryIdArray);

  const getData = async (auditId) => {
    try {
      let data = {
        query: `SELECT al.*, c.companyName, u.userName FROM audit_log al LEFT JOIN clients c ON al.clientId = c.id LEFT JOIN users u ON al.userId = u.id where al.id=${auditId}`,
      };
      const res = await axiosWithToken.post(BASE_URL + `dynamic`, data);

      // Parse old_value and new_value fields
      const parsedData = res.data.map((item) => ({
        ...item,
        old_value: JSON.parse(item.old_value),
        new_value: JSON.parse(item.new_value),
      }));

      return parsedData;
    } catch (err) {
      console.log("Data fetching error: ", err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchDataForAllIds = async () => {
      const promises = AuditIDArray.map((auditId) => getData(auditId));

      try {
        const results = await Promise.all(promises);
        setAuditData(results.flat());
      } catch (err) {
        console.log("error", err);
      }
    };

    if (AuditIDArray && AuditIDArray.length > 0) {
      fetchDataForAllIds();
    }
  }, []);

  const generateTableData = (auditData) => {
    const newTableDataArray = [];

    for (const item of auditData) {
      const changeArray = [];

      if (item.new_value) {
        for (const key in item.new_value) {
          changeArray.push({
            name: key,
            preValue: item.old_value ? item.old_value[key] || null : null,
            latestValue: item.new_value[key] || null,
          });
        }
      }

      newTableDataArray.push(changeArray);
    }

    return newTableDataArray;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [40, 0, 40, 0],
          filename: `AuditData_.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(9);
          pdf.setTextColor(0, 0, 0);
          pdf.setFont("Courier");

           // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 15;
        const borderX = leftMargin;
        const borderY = 30;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 800);

        }
        const blob = pdf.output("blob");
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `AuditData.pdf`;
        a.click();

      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  useEffect(() => {
    setTableDataArray(generateTableData(auditData));
  }, [auditData]);

  return (
    <div>
      <Toolbar style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
          marginRight:10}}>
      <Button
          variant="contained"
          style={{backgroundColor:"grey"}}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          generatePDF();
        }}
      >
        Download PDF
      </Button>
      </Toolbar>
      <div ref={printComponentRef}>
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {tableDataArray?.map((tableData, tableIndex) => (
            <div
              key={tableIndex}
              style={{
                marginTop: "30px",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Divider sx={{ borderTop: "2px solid" }} />
              <Typography
                variant="h6"
                sx={{ textAlign: "left", margin: "10px", fontWeight: "bold" }}
                color="initial"
              >
                Table of {auditData[tableIndex].type}
              </Typography>
              <Paper
                elevation={3}
                style={{
                  padding: "20px",
                  marginBottom: "20px",
                  width: "40%",
                  minWidth: "585px",
                  //  margin: "auto",
                  textAlign: "left",
                }}
              >
                <Typography variant="h6">
                  <span style={textStyle}>Type:</span>{" "}
                  {auditData[tableIndex].type}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>Identifier:</span>{" "}
                  {auditData[tableIndex].identifier}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>User:</span>{" "}
                  {auditData[tableIndex].userName}
                </Typography>
                <Typography variant="h6">
                  <span style={textStyle}>Time:</span>{" "}
                  {moment(auditData[tableIndex].change_date).format(
                    "DD-MM-YYYY"
                  )}
                </Typography>

                <Typography variant="h6">
                  <span style={textStyle}>Action:</span>{" "}
                  {auditData[tableIndex].action}
                </Typography>
              </Paper>

              <Table
                stickyHeader
                aria-label={`sticky table ${tableIndex}`}
                class="table-responsive"
                size="small"
                style={{ marginTop: "50px" }}
                id={`datatable-keytable-${tableIndex}`}
              >
                <TableHead>
                  <TableRow style={tableStyle}>
                    <TableCell style={tableStyle}>Column Name</TableCell>
                    {auditData[tableIndex].action !== "create" && (
                      <TableCell style={tableStyle}>Previous Value</TableCell>
                    )}
                    <TableCell style={tableStyle}>Updated Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((column, columnIndex) => (
                    <TableRow>
                      <TableCell>{column?.name}</TableCell>
                      {auditData[tableIndex].action !== "create" && (
                        <TableCell>
                          {column?.preValue
                            ?.replaceAll("||", ",")
                            ?.replaceAll("|", " to ")}
                        </TableCell>
                      )}
                      <TableCell>
                        {column?.latestValue
                          ?.replaceAll("||", ",")
                          ?.replaceAll("|", " to ")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ))}
        </div>
      </div>
      {downloadPdfloading && (
          <DownloadPdfLoaderModal
            loading={downloadPdfloading}
            name={"Audit Log"}
          />
        )}
    </div>
  );
};

export default ViewLogs;
