import { driver } from "driver.js";

export const quotationListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Quotation list.",
        },
      },
      {
        element: "#quotationlist_createbtn",
        popover: {
          title: "Create New Quotation",
          description: "Click this button to create a new quotation.",
        },
      },
      {
        element: "#quotationlist_datefrom",
        popover: {
          title: "Select Date",
          description: "Select the start date.",
        },
      },
      {
        element: "#quotationlist_dateto",
        popover: {
          title: "Select Date",
          description: "Select the end date.",
        },
      },
      {
        element: "#quotationlist_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#quotationlist_searchbytext",
        popover: {
          title: "Enter / Update Your Value",
          description:
            "When you select the previous dropdown value, enter/update your value for the search.",
        },
      },
      {
        element: "#quotationlist_searchbtn",
        popover: {
          title: "Search",
          description:
            "When you select the previous dropdown or enter/update a value, click this button to get the result.",
        },
      },
      {
        element: "#stdMastersEQPList_convertexcel",
        popover: {
          title: "Print All Quotations",
          description: "Below the list, you can get an Excel file.",
        },
      },
      {
        element: "#quotationlist_table",
        popover: {
          title: "Quotation Table",
          description:
            "Quotation table where you can view and manage quotations.",
        },
      },
      {
        element: "#quotationlist_edit",
        popover: {
          title: "Edit Quotation",
          description: "Click this button to edit the quotation.",
        },
      },
      {
        element: "#quotationlist_followup",
        popover: {
          title: "Quotation Follow-Up",
          description: "Click this button to go to the quotation follow-up.",
        },
      },
      {
        element: "#quotationlist_createInvoice",
        popover: {
          title: "Create Invoice",
          description:
            "Click this button to go to the invoice where using the quotation you can create an invoice.",
        },
      },
      {
        element: "#quotationlist_mail",
        popover: {
          title: "Send Mail",
          description: "Send Mail.",
        },
      },
      {
        element: "#quotationlist_download",
        popover: {
          title: "Download Quotation",
          description: "Download Quotation",
        },
      },
      {
        element: "#quotationlist_delete",
        popover: {
          title: "Delete Quotation",
          description: "Click this button to delete the quotation.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage quotations.",
        },
      },
    ],
  });
};

export const createQuotationGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in creating / editing a Quotation.",
        },
      },
      {
        element: "#quotation_create_company",
        popover: {
          title: "Select Company",
          description: "Select the company using the dropdown.",
        },
      },
      {
        element: "#quotation_create_address",
        popover: {
          title: "Select Address",
          description:
            "When the company is selected, the address automatically fetches, and if more than one address, then you have to select the address.",
        },
      },
      {
        element: "#quotation_create_quotationNumber",
        popover: {
          title: "Quotation Number",
          description: "This quotation number is automatically generated.",
        },
      },
      {
        element: "#quotation_create_enquiryId",
        popover: {
          title: "Enquiry ID",
          description: "Enter / Update enquiryId.",
        },
      },
      {
        element: "#quotation_create_enquiryDate",
        popover: {
          title: "Select Enquiry Date",
          description: "You have to select the enquiry Date.",
        },
      },
      {
        element: "#quotation_create_referenceNumber",
        popover: {
          title: "Enter / Update Reference Number",
          description: "Optional Field.",
        },
      },
      {
        element: "#quotation_create_subject",
        popover: {
          title: "Subject",
          description: "Optional field.",
        },
      },
      {
        element: "#quotation_create_qtDate",
        popover: {
          title: "Select Quotation Date",
          description: "You have to select the quotation Date.",
        },
      },
      {
        element: "#quotation_create_termsConditions",
        popover: {
          title: "Update Terms Conditions",
          description: "Fetch Automatically from setting.",
        },
      },
      {
        element: "#quotation_create_gstType",
        popover: {
          title: "Select GST Type",
          description: "Select GST Type using dropdown.",
        },
      },
      {
        element: "#quotation_create_gstPercentage",
        popover: {
          title: "Select GST Percentage",
          description: "Select GST Percentage using dropdown.",
        },
      },
      {
        element: "#quotation_create_quotationValidity",
        popover: {
          title: "Enter / Update Quotation Validity",
          description: "Optional field.",
        },
      },
      {
        element: "#quotation_create_equipmentCollection",
        popover: {
          title: "Enter / Update Equipment Collection",
          description: "Optional field.",
        },
      },
      {
        element: "#quotation_create_equipmentDelivery",
        popover: {
          title: "Enter Equipment Delivery",
          description: "Optional field.",
        },
      },
      {
        element: "#quotation_create_excelUpload_uploadexcel",
        popover: {
          title: "Upload Excel",
          description:
            "Where you can upload an Excel file then fetch products and show below table.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_addnewproduct",
        popover: {
          title: "Add New Row below Table",
          description: "Click this button to add a new row for the Quotation.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_equipmentId",
        popover: {
          title: "Select / Update Equipment Name",
          description: "Select / Update Equipment Name.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_description",
        popover: {
          title: "Enter / Update Description",
          description:
            "When Equipment is selected, it automatically fetches the description. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_hsnCode",
        popover: {
          title: "Select HSN / SAC Code",
          description:
            "When Equipment is selected, it automatically fetches the HSN / SAC code. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_service",
        popover: {
          title: "Select Service",
          description:
            "When Equipment is selected, it automatically fetches the service. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_rate",
        popover: {
          title: "Rate",
          description:
            "When Equipment is selected, it automatically fetches the rate. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_quantity",
        popover: {
          title: "Quantity",
          description:
            "When Equipment is selected, it automatically fetches the quantity. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_discount",
        popover: {
          title: "Discount",
          description:
            "When Equipment is selected, it automatically fetches the discount. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_amount",
        popover: {
          title: "Amount",
          description:
            "When Equipment is selected, it automatically fetches the amount. If you want to change, you can also make changes.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_proddelete",
        popover: {
          title: "Delete Row",
          description: "Click this button to delete the current row.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_conveyanceCharges",
        popover: {
          title: "Enter / update Conveyance Charges",
          description: "Enter / update Conveyance Charges.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_courierCharges",
        popover: {
          title: "Enter / update Courier Charges",
          description: "Enter / update Courier Charges.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_bottomdiscount",
        popover: {
          title: "Enter Discount",
          description: "Enter your discount.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_totalAmount",
        popover: {
          title: "Total Amount",
          description: "Total Amount before Tax.",
        },
      },
      {
        element: "#quotation_create_ProductDetails_SGST",
        popover: {
          title: "SGST",
          description:
            "Calculated SGST where you selected (This shows when State GST is selected).",
        },
      },
      {
        element: "#quotation_create_ProductDetails_CGST",
        popover: {
          title: "CGST",
          description:
            "Calculated CGST where you selected (This shows when State GST is selected).",
        },
      },
      {
        element: "#quotation_create_ProductDetails_GST",
        popover: {
          title: "GST",
          description:
            "Calculated GST where you selected (This shows when General GST is selected).",
        },
      },
      {
        element: "#quotation_create_ProductDetails_grandTotal",
        popover: {
          title: "Calculated Grand Total Amount",
          description: "Calculated Amount with Tax.",
        },
      },
      {
        element: "#quotation_create_save",
        popover: {
          title: "Save Quotation",
          description: "Click this button to save the quotation.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create a quotation.",
        },
      },
    ],
  });
};

export const viewQuotationGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this View Quotation.",
        },
      },
      {
        element: "#quotation_view_letterhead",
        popover: {
          title: "Select Letterhead",
          description: "Select letterhead with or without",
        },
      },
      {
        element: "#quotation_view__electronicsign",
        popover: {
          title: "Select Electronic Sign",
          description: "Select electronic sign with or without.",
        },
      },
      {
        element: "#quotation_view_Download",
        popover: {
          title: "Download quotation",
          description:
            "Clicking this button allows you to download the quotation.",
        },
      },
      {
        element: "#quotation_view__print",
        popover: {
          title: "quotation Print",
          description: "",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can download the Quotation",
        },
      },
    ],
  });
};

export const quotationFollowUpGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Quotation Follow-Up list.",
        },
      },
      {
        element: "#quotation_followUp_qtid",
        popover: {
          title: "ID",
          description: "Quotation Id Fetch Do not Change",
        },
      },
      {
        element: "#quotation_followUp_remark",
        popover: {
          title: "Enter Remark",
          description: "Enter Remark.",
        },
      },
      {
        element: "#quotation_followUp_estimateClosureDate",
        popover: {
          title: "Select Estimate Closure Date",
          description: "Select the Estimate Closure date.",
        },
      },
      {
        element: "#quotation_followUp_conversionRatio",
        popover: {
          title: "Enter Conversion Ratio",
          description: "Enter Conversion Ratio.",
        },
      },
      {
        element: "#quotation_followUp_addbtn",
        popover: {
          title: "Add Follow UP",
          description:
            "When Remark ,Estimate ClosureDate and Conversion Ratio filled then click this button.",
        },
      },
      {
        element: "#quotation_followUp_table",
        popover: {
          title: "Follow-Up Report Table",
          description:
            "Follow-up report table where you can view and manage the follow-up reports.",
        },
      },
      {
        element: "#quotation_followUp_table_deltebtn",
        popover: {
          title: "Delete Row",
          description: "Delete Current Row",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage Quotation Follow Up reports.",
        },
      },
    ],
  });
};
