import { driver } from "driver.js";

export const createProductGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in creating a product.",
        },
      },
      {
        element: "#product_create_company",
        popover: {
          title: "Select Company",
          description: "Select Company using dropdown.",
        },
      },
      {
        element: "#product_create_instruname",
        popover: {
          title: "Select Instrument",
          description: "Select Instrument using dropdown.",
        },
      },
      {
        element: "#product_create_rate",
        popover: {
          title: "Rate",
          description: "Enter the rate.",
        },
      },
      {
        element: "#product_create_save",
        popover: {
          title: "Save Product",
          description: "Click this button to save the product.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create a product.",
        },
      },
    ],
  });
};

export const editProductGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in editing a product.",
        },
      },
      {
        element: "#product_edit_compnay",
        popover: {
          title: "Select Company",
          description: "Edit the company using the dropdown.",
        },
      },
      {
        element: "#product_edit_instruname",
        popover: {
          title: "Select Instrument",
          description: "Edit the instrument using the dropdown.",
        },
      },
      {
        element: "#product_edit_rate",
        popover: {
          title: "Rate",
          description: "Edit the rate.",
        },
      },
      {
        element: "#product_edit_save",
        popover: {
          title: "Save Product",
          description: "Click this button to save the product.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you have successfully edited the product.",
        },
      },
    ],
  });
};

export const productListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this product list.",
        },
      },
      {
        element: "#product_list_createbtn",
        popover: {
          title: "Create New Product",
          description: "Click this button to create a new product.",
        },
      },
      {
        element: "#product_list_table",
        popover: {
          title: "Product Table",
          description:
            "Product details table where you can edit and delete products.",
        },
      },
      {
        element: "#product_list_edit",
        popover: {
          title: "Edit Product",
          description: "Click this button to edit the product.",
        },
      },
      {
        element: "#product_list_delete",
        popover: {
          title: "Delete Product",
          description: "Click this button to delete the product.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create and manage products.",
        },
      },
    ],
  });
};
