import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const typeASlice = createSlice({
  name: "typeA",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addSD: (state, action) => {
      state.value = {
        ...state.value,
        [action.payload.datasheetReadingId]: {stdDevOfMean: action.payload.stdDevOfMean, meanOfStdDev: action.payload.meanOfStdDev}
      };
    },
  },
});

export const { addSD } = typeASlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTypeA = (state) => state.typeA;

export default typeASlice.reducer;
