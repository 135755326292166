import { driver } from "driver.js";
export const viewDocumentGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Document Upload Section.",
        },
      },
      {
        element: "#viewDocument_list_createbtn",
        popover: {
          title: "Upload New Document",
          description:
            "Click this button to create a new document to be upload",
        },
      },
      {
        element: "#viewDocument_list_table",
        popover: {
          title: "Document Upload Table",
          description:
            "Document Upload table where you can edit ,view and delete document.",
        },
      },
      {
        element: "#viewDocument_list_edit",
        popover: {
          title: "Edit Document",
          description: "Click this button to edit the document.",
        },
      },
      {
        element: "#viewDocument_list_delete",
        popover: {
          title: "Delete Document",
          description: "Click this button to delete the document.",
        },
      },
      {
        element: "#viewDocument_list_view",
        popover: {
          title: "View Document",
          description: "Click this button to view the document.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can view the document upload Section",
        },
      },
    ],
  });
};
export const createDocumentGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Document Upload Section.",
        },
      },
      {
        element: "#create_document_select",
        popover: {
          title: "Select Document",
          description: "Select which document want to be upload using dropdown",
        },
      },
      {
        element: "#create_document_uploadfile",
        popover: {
          title: "Upload File",
          description: "Click this button to upload file",
        },
      },
      {
        element: "#create_document_save",
        popover: {
          title: "Save Document",
          description: "Click this button to save the document.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can upload the document",
        },
      },
    ],
  });
};
