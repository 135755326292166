import { driver } from "driver.js";

export const createInstrumentGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in creating an Instrument.",
        },
      },
      {
        element: "#instrumentlist_create_discipline",
        popover: {
          title: "Select Discipline",
          description: "Select discipline using dropdown.",
        },
      },
      {
        element: "#instrumentlist_create_instrumentname",
        popover: {
          title: "Instrument Name",
          description: "Enter Instrument name.",
        },
      },
      {
        element: "#instrumentlist_create_calibproced",
        popover: {
          title: "Calibration Procedure No",
          description: "Enter calibration procedure number.",
        },
      },
      {
        element: "#instrumentlist_create_master_issdtandards",
        popover: {
          title: "Referenced Standards",
          description: "Enter referenced standards.",
        },
      },
      {
        element: "#instrumentlist_create_supportive",
        popover: {
          title: "Supportive Instruments",
          description: "Select supportive instruments.",
        },
      },
      {
        element: "#instrumentlist_create_gauage",
        popover: {
          title: "Gauge",
          description: "Select gauge.",
        },
      },
      {
        element: "#instrumentlist_create_materialType",
        popover: {
          title: "Material Type",
          description: "Select Material Type.",
        },
      },
      {
        element: "#instrumentlist_create_formateno",
        popover: {
          title: "Format Number",
          description: "Enter format number.",
        },
      },
      {
        element: "#instrumentlist_create_reviosno",
        popover: {
          title: "Revision Number",
          description: "Enter revision number.",
        },
      },
      {
        element: "#instrumentlist_create_revisondate",
        popover: {
          title: "Revision Date",
          description: "Select revision date.",
        },
      },
      {
        element: "#instrumentlist_create_add_master",
        popover: {
          title: "Add Master",
          description:
            "Click this button below to add a row where you can add Std Master ID and universal factors.",
        },
      },
      {
        element: "#instrumentlist_create_selectmasterid",
        popover: {
          title: "Standard Master ID",
          description: "Select Standard Master ID using dropdown.",
        },
      },
      {
        element: "#instrumentlist_create_selectmasteridtoggle",
        popover: {
          title: "Standard Master ID",
          description:
            "When you click this button, you can toggle by discipline.",
        },
      },
      {
        element: "#instrumentlist_create_uncertaintyfact",
        popover: {
          title: "Uncertainty Factors",
          description:
            "Select Uncertainty Factors using dropdown, and you can select multiple.",
        },
      },
      {
        element: "#instrumentlist_create_factedit",
        popover: {
          title: "Edit Row",
          description: "Click this button to edit the current row.",
        },
      },
      {
        element: "#instrumentlist_create_factdelete",
        popover: {
          title: "Delete Row",
          description: "Click this button to delete the current row.",
        },
      },
      {
        element: "#instrumentlist_create_addnablscope",
        popover: {
          title: "Add Master",
          description:
            "Click this button below to add a row where you can add NABL scope.",
        },
      },
      {
        element: "#instrumentlist_create_nablscope",
        popover: {
          title: "NABL Scope Table",
          description: "",
        },
      },
      {
        element: "#instrumentlist_create_copynabl",
        popover: {
          title: "Copy Row",
          description:
            "Click this button, then this row will be copied to create a new row with previous values.",
        },
      },
      {
        element: "#instrumentlist_create_nablevalue",
        popover: {
          title: "Enter Value",
          description: "Enter value.",
        },
      },
      {
        element: "#instrumentlist_create_nablunit",
        popover: {
          title: "Select Unit",
          description: "Select unit.",
        },
      },
      {
        element: "#instrumentlist_create_nabl_delete",
        popover: {
          title: "Delete Row",
          description: "Click this button to delete the current row.",
        },
      },
      {
        element: "#instrumentlist_create_save",
        popover: {
          title: "Save Instrument",
          description: "Click this button to save the Instrument.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create an Instrument.",
        },
      },
    ],
  });
};

export const instrumentListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Instrument List.",
        },
      },
      {
        element: "#Instrument_list_createbtn",
        popover: {
          title: "Create New Instrument",
          description:
            "When you click this button, you can create a new Instrument.",
        },
      },
      {
        element: "#Instrument_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#Instrument_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter the value you want to search.",
        },
      },
      {
        element: "#Instrument_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#Instrument_list_table",
        popover: {
          title: "Instrument Table",
          description:
            "Table displaying details where you can edit Instrument and Delete.",
        },
      },
      {
        element: "#Instrument_list_edit",
        popover: {
          title: "Edit Instrument",
          description: "Click this button to edit the Instrument.",
        },
      },
      {
        element: "#Instrument_list_copy",
        popover: {
          title: "Copy Instrument",
          description:
            "Click this button to copy this Instrument and create a new Instrument with existing values.",
        },
      },
      {
        element: "#Instrument_list_delete",
        popover: {
          title: "Instrument Delete",
          description:
            "When clicking this button, you can delete the Instrument.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create Instrument.",
        },
      },
    ],
  });
};
