import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";

export const TableCell = withStyles({
  root: {
    border: "none",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

export const useStyles = makeStyles({
  table: {
    border: "1px solid black",
    padding: "1px",
  },
  infoCell: {
    maxHeight: "5px",
  },
  innerCell: {
    padding: "0px",
    maxWidth: "40%",
  },
  formatedCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
  },
  certificateCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
    width: "20%",
  },
  sxPad: {
    padding: "5px",
  },
  addressCell: {
    width: "200px",
  },
});

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  '@media (max-width: 600px)': {
    width: "80%",  // Adjust the width for screens up to 768px wide
  },  
  '@media (max-width: 480px)': {
    width: "90%",  // Adjust the width for screens up to 480px wide
  },
  height: 312,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "5px",
};

export const historyModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
    '@media (max-width: 600px)': {
      width: "80%",  // Adjust the width for screens up to 768px wide
    },  
    '@media (max-width: 480px)': {
      width: "90%",  // Adjust the width for screens up to 480px wide
    },
  height: 312,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "5px",
};
