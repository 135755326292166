import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export const generateAudit = (
  type,
  identifier,
  user,
  time,
  action,
  remarks,
  company
) =>
  axiosWithToken
    .post(BASE_URL + "/audit", {
      type,
      identifier,
      user,
      time,
      action,
      remarks,
      company,
    })
    .then((res) => {
      console.log("audit log generated successful!");
    })
    .catch((err) => console.log("audit error : ", err));

export default generateAudit;
