import { driver } from "driver.js";

export const masterDatasheetTemplateListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Datasheet Template list.",
        },
      },
      {
        element: "#datasheet_template_list_createbtn",
        popover: {
          title: "Create New Datasheet Template",
          description: "Click this button to create a new Datasheet Template.",
        },
      },
      {
        element: "#datasheet_template_list_table",
        popover: {
          title: "Datasheet Template Table",
          description:
            "Datasheet Template details table where you can edit and delete Datasheet Templates.",
        },
      },
      {
        element: "#datasheet_template_list_edit",
        popover: {
          title: "Edit Datasheet Template",
          description: "Click this button to edit the Datasheet Template.",
        },
      },
      {
        element: "#datasheet_template_list_delete",
        popover: {
          title: "Delete Datasheet Template",
          description: "Click this button to delete the Datasheet Template.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create and manage Datasheet Templates.",
        },
      },
    ],
  });
};
