import { driver } from "driver.js";
export const dashboardGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this dashboard.",
        },
      },
      {
        element: "#dashboard_clients",
        popover: {
          title: "Client",
          description: "Total Client Count",
        },
      },
      {
        element: "#dashboard_certificates",
        popover: {
          title: "Certificates",
          description: "Total Certificates Count",
        },
      },
      {
        element: "#dashboard_active_Ins",
        popover: {
          title: "Active Ins.",
          description: "Total Active Ins. Count",
        },
      },
      {
        element: "#dashboard_renewal",
        popover: {
          title: "Renewal",
          description: "Total Renewal Count",
        },
      },
      {
        element: "#dashboard_todayclients",
        popover: {
          title: "Client",
          description: "Total Roday Client Count",
        },
      },
      {
        element: "#dashboard_SRF",
        popover: {
          title: "SRF",
          description: "Total Today SRF Count",
        },
      },
      {
        element: "#dashboard_todaycertificates",
        popover: {
          title: "Certificates",
          description: "Total Today Certificates Count",
        },
      },
      {
        element: "#dashboard_todayrenewal",
        popover: {
          title: "Renewal",
          description: "Total Today Renewal Count",
        },
      },
      // {
      //   element: "#dashboard_commercial_today_report",
      //   popover: {
      //     title: "Commercial Today's Report",
      //     description: "Commercial Today's Report Table",
      //   },
      // },
      // {
      //   element: "#dashboard_commercial_std",
      //   popover: {
      //     title: "Standard Out-in",
      //     description: "Standard Out-in Table",
      //   },
      // },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can view the dashboard",
        },
      },
    ],
  });
};
