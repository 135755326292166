import { configureStore } from "@reduxjs/toolkit";

import typeAReducer from "../components/typeA/typeASlice";
import loginSlice from "../components/login/loginSlice";
import globalDataSlice from "../components/globalDataSlice";
import enquirySlice from "../components/master/enquiry/enquirySlice";

// const config = {
//   // TOGGLE_TODO will not be triggered in other tabs
//   blacklist: ["TOGGLE_TODO"],
// };
// const middlewares = [createStateSyncMiddleware(config)];

export const store = configureStore({
  reducer: {
    login: loginSlice,
    typeA: typeAReducer,
    globalData: globalDataSlice,
    enquiry: enquirySlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), //.concat(middlewares),
});

// initMessageListener(store);
